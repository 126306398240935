main {
  padding: 0 5em;
  background-color: #020633;
  height: max-content;
  padding-bottom: 4em;
}

.post_title {
  text-align: center;
  padding: 1em;
  text-transform: uppercase;
  color: orange;
}

.post_image {
  text-align: center;
}

.content {
  color: rgb(199, 199, 199);
  max-width: 100%;
  display: grid;
  justify-content: center;
}
