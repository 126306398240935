
.project_container {
    padding: 0 2em;
    background-color: #020633;
    height: max-content;
}

.page_title {
    text-align: center;
    padding: 2em;
    text-transform: uppercase;
}

.page_title h1 {
    color: rgb(199, 199, 199);
}

.card_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.card {
    margin-bottom: 2em;

}

.card_body {
    background-color: #020633;
    color: rgb(199, 199, 199);
}

.tech {
    font-size: 0.8em;
    margin: 1em 0;
    font-style: italic;
}

.project_blurb {
    color: rgb(199, 199, 199);
    text-align: center;
    margin-bottom: 2em;
}