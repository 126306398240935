.blog_container {
  padding: 0 5em;
  background-color: #020633;
  height: 100vh;
  color: rgb(199, 199, 199);
}

.page_title {
  text-align: center;
  padding: 2em;
  text-transform: uppercase;
}

.blog_link {
  color: orange;
}

.blog_blurb {
  color: rgb(199, 199, 199);
  text-align: center;
  margin-bottom: 2em;
}
.blog_posts {
  /* border-color: orange;
    border-width: 1px;
    border-style: solid; */
  color: orange;
  font-size: 20px;
}

article {
  margin-top: 1.5em;
}

.individual {
  /* display: inline-flex; */
}

.blog_post_link {
  color: orange;
  font-weight: bolder;
}

.blog_post_link:hover {
  color: rgb(199, 199, 199);
  font-weight: bolder;
}

.blog_post_date {
  color: white;
  font-style: italic;
  font-size: 0.7em;
}
