.navigation_bar {
    background-color: #020633;
    color: rgb(199, 199, 199);
    border-bottom: rgb(199, 199, 199) 3px solid;
    font-weight: bolder;
}

#nav_brand {
    color: rgb(199, 199, 199);
    font-weight: bolder;
}

#nav_brand i {
    color: orange;
    font-weight: bolder;
}

#nav_links {
    color: rgb(199, 199, 199);
    text-transform: uppercase;
    padding: 1em;
}

#nav_links:hover {
    color: orange;
    font-weight: bolder;
}

i {
    color: rgb(199, 199, 199);
    padding: 0.5em;
}


