.contact_container {
  padding: 0 2em;
  background-color: #020633;
  height: 100vh;
  color: rgb(199, 199, 199);
  align-items: center;
  justify-content: center;
}

.page_title {
  text-align: center;
  padding: 2em;
  text-transform: uppercase;
}

.contact_form {
  padding: 1.5em;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  border: orange 2px solid;
  border-radius: 20px;
  margin-bottom: 5em;
  margin: 0 auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 2em;
  padding: 0.5em;
}

label {
  margin-top: 1em;
}

.submit_button {
  background-color: orange;
  margin-top: 0.8em;
  max-width: 200px;
  border: none;
}
