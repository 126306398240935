.footer {
    background-color: #020633;
    padding-top: 3em;
    padding-bottom: 3em;
    color: rgb(199, 199, 199);
    text-align: center;
    font-size: 0.3em;
    position: relative;
    border-top: rgb(199, 199, 199) 3px solid;


}