
.about_container {
    padding: 0 2em;
    background-color: #020633;
    height: max-content;
    color: rgb(199, 199, 199);
    padding-bottom: 4em;
}


.page_title {
    text-align: center;
    padding: 2em;
    text-transform: uppercase;
}

.about_me {
    padding: 1em;
}

.about_me a {
    color: orange;
}

h5 {
    color: orange;
    text-align: center;
    margin-top: 2em;
}

.certifications {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    

}
.certifications img {
    height: 100px;
    width: 100px;
    margin: 0.5em;
}

.languages {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    

}
.languages img {
    height: 100px;
    width: 100px;
    margin: 0.5em;
}