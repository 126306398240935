.hero_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
}

.hero_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video_bg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}

.hero_content {
    z-index: 3;
    margin-top: -50px;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero_title {
    color: rgb(199, 199, 199);
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
}

.hero_blurb {
    margin-top: 24px;
    color: rgb(199, 199, 199);
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}

.hero_info {
    color: rgb(199, 199, 199);
    margin-top: 1em;
    font-style: italic;
}

.hero_info a {
    color: orange;
    font-weight: bold;
}

.hero_info a:hover {
    color: rgb(199, 199, 199);
}

.hero_social {
    padding: 1em;
}

.hero_social i {
    color: orange;
    font-size: 1.5em;
    padding: 1em;
    align-items: center;
    justify-content: center;
}

.hero_social i:hover {
    color: rgb(199, 199, 199);
  
}

